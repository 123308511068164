import axios from "axios";

/**
 * Service to call location endpoints
 */
const LocationService = {
  /**
   * create a new location
   * @param params
   * @returns {*}
   */
  create(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("locations", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a single location by ID
   * @param id
   * @returns {*}
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`locations/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all locations
   * @param  {} slug
   */
  all() {
    return new Promise((resolve, reject) => {
      axios
        .get("locations")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all locations but filtered
   * @param  {} slug
   */
  filter(slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`locations?${slug}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update a single location by ID
   * @param id
   * @returns {*}
   */
  update(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`locations/${id}`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Deletes a location
   * @param  {} id
   */
  delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`locations/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default LocationService;
