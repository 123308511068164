import axios from "axios";

/**
 * Service to call company endpoints
 */
const CompanyService = {
  /**
   * create a new company
   * @param params
   * @returns {*}
   */
  create(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("companies", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a company by ID
   * @param id
   * @returns {*}
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`companies/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update a company by ID
   * @param id
   * @returns {*}
   */
  update(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`companies/${id}`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Auto populate company details
   * @param params
   * @returns {*}
   */
  autoPopulate(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`companies/pre-populate`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update company action party type
   * @param params
   * @returns {*}
   */
  setActionParty(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`companies/set-action-party`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update company onboarding stage
   * @param params
   * @returns {*}
   */
  setOnboardingStage(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`companies/set-onboarding-stage`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default CompanyService;
